import { useQuery } from "@tanstack/react-query";
import { createContext, useContext, useReducer } from "react";
import { useAuth } from "../../../../../providers/AuthProvider";
import api from "../../../../../api";

// Contexts for managing withdraws report state and dispatch
const WithdrawsReportContext = createContext();
const WithdrawsReportDispatchContext = createContext();

// Action types for reducer
export const WITHDRAWS_REPORT_ACTIONS = {
  UPDATE_STATE: "updateState",
  UPDATE_STATES: "updateStates",
};

// Provider component to manage the withdraws report state
export const WithdrawsReportProvider = ({ children }) => {
  const { authTokens } = useAuth();

  // Query to fetch the withdraws report from the API
  const { data, status, refetch } = useQuery({
    queryKey: ["withdrawsReport"],
    queryFn: () => api.getWithdrawsReport({ token: authTokens.IdToken }),
    enabled: false, // Disabled until manually triggered
  });

  // Function to fetch and update withdraws report state
  const fetchWithdrawsReport = async () => {
    withdrawsReportDispatch({ type: WITHDRAWS_REPORT_ACTIONS.UPDATE_STATE, key: "isFetchingWithdrawsReport", value: true });

    const result = await refetch();

    withdrawsReportDispatch({ type: WITHDRAWS_REPORT_ACTIONS.UPDATE_STATE, key: "isFetchingWithdrawsReport", value: false });

    if (result.status === "success") {
      const updatedWithdrawsReport = result.data.data.data.map((item) => {
        const withdraw = item.attributes;
        withdraw.transactionID = withdraw.id.split("-")[0];
        return withdraw;
      });

      withdrawsReportDispatch({
        type: WITHDRAWS_REPORT_ACTIONS.UPDATE_STATE,
        key: "withdrawsReport",
        value: updatedWithdrawsReport,
      });
    }
  };

  // Reducer to manage the withdraws report state
  const [withdrawsReportState, withdrawsReportDispatch] = useReducer(WithdrawsReportReducer, {
    withdrawsReport: [],
    isFetchingWithdrawsReport: false,
    fetchWithdrawsReport,
    getWithdrawsReport: { data, status, refetch },
  });

  return (
    <WithdrawsReportContext.Provider value={withdrawsReportState}>
      <WithdrawsReportDispatchContext.Provider value={withdrawsReportDispatch}>
        {children}
      </WithdrawsReportDispatchContext.Provider>
    </WithdrawsReportContext.Provider>
  );
};

// Custom hook to access withdraws report state
export const useWithdrawsReport = () => useContext(WithdrawsReportContext);

// Custom hook to access withdraws report dispatch
export const useWithdrawsReportDispatch = () => useContext(WithdrawsReportDispatchContext);

// Reducer to handle actions related to withdraws report state
const WithdrawsReportReducer = (state, action) => {
  switch (action.type) {
    case WITHDRAWS_REPORT_ACTIONS.UPDATE_STATE:
      return {
        ...state,
        [action.key]: action.value,
      };
    case WITHDRAWS_REPORT_ACTIONS.UPDATE_STATES:
      return {
        ...state,
        ...action.states.reduce((acc, { key, value }) => {
          acc[key] = value;
          return acc;
        }, {}),
      };
    default:
      throw new Error(`[WithdrawsReportReducer] Unknown action: ${action.type}`);
  }
};
