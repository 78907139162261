import React, { useState } from "react";
import "./model.scss";
import { useNavigate } from "react-router-dom";
import AddBankAccount from "./payment_methods/add_bank_account";
import AddPhoneNumber from "./payment_methods/add_phone_number";


import closePopIcon   from "../../assets/settings/close_pop.svg";
import closeBackIcon  from "../../assets/back_button.svg";

import TradingAccount from  "./wallets/main/trading";
import WithdrawAccount from "./wallets/main/withdraw";
import DepositAccount from  "./wallets/main/deposit";
import AccountWallet from   "./wallets/main/wallet";

import ConfirmDeposit from   "./wallets/main/content/deposit/confirm-deposit";
import ConfirmWithdraw from   "./wallets/main/content/withdraw/confirm-withdraw";
const PopModel = ({toggleOperationMethodType, openModel, openModelFunc, modelContent, modeCloseResponse}) => {

    if (!openModel) return null;
	const navigate = useNavigate();
	const handleMenuItemClick = (menuItem) => {
		setSelectedMenuItem(menuItem);
	};

	const handleModelClose = (e) => {
		if(modelContent?.operation_method_type_step_2 !== undefined){
            openModelFunc({operation_type: modelContent?.operation_type_step_2, operation_method_type:modelContent?.operation_method_type_step_2, data: modelContent?.data});
		} else {
			openModelFunc();
		}
	};

	return (
	  <div className={openModel? "model_page model_page_trade  nopadding":"model_page model_page_trade hidden nopadding"}>  
		<div  className="clearfix group accountsettings_paymentmethods_model_box_trade">

			<div className="accountsettings_paymentmethods_model_close clearfix group">
			  <img src={(modelContent?.operation_method_type_step_2 !== undefined)? closeBackIcon:closePopIcon} alt="" className="close_model_icon_trade" onClick={(e) => handleModelClose()}/>
			</div>	
			
			{/**
			 *   trading, credit and debit model section
			 * **/}
			{/* <div className={(modelContent?.operation_type === 'crediting') ? "wallet_top_options clearfix group flexing_content flex_container  text-center margin_bottom_15": "hidden"}>
			  <span  onClick={() => toggleOperationMethodType({operation_type: 'crediting', operation_method_type: 'trade' })} className={(modelContent?.operation_method_type === 'trade')? "active_tab":""}>TRADE</span>
			  <span  onClick={() => toggleOperationMethodType({operation_type: 'crediting', operation_method_type: 'deposit' })} className={(modelContent?.operation_method_type === 'deposit')? "active_tab":""}>DEPOSIT</span>
			  <span  onClick={() => toggleOperationMethodType({operation_type: 'crediting', operation_method_type: 'withdraw' })} className={(modelContent?.operation_method_type === 'withdraw')? "active_tab":""}>WITHDRAW</span>
			</div>	 */}

            <div className={(modelContent?.operation_type === 'crediting') ? "wallet_top_options clearfix group flexing_content flex_container  text-center margin_bottom_15": "hidden"}>
			  <span  onClick={() => toggleOperationMethodType({operation_type: 'crediting', operation_method_type: 'trade' })} className={(modelContent?.operation_method_type === 'trade')? "active_tab_2":"hidden"}>Trade</span>
			  <span  onClick={() => toggleOperationMethodType({operation_type: 'crediting', operation_method_type: 'deposit' })} className={(modelContent?.operation_method_type === 'deposit')? "active_tab_2":"hidden"}>Wallet Deposit</span>
			  <span  onClick={() => toggleOperationMethodType({operation_type: 'crediting', operation_method_type: 'withdraw' })} className={(modelContent?.operation_method_type === 'withdraw')? "active_tab_2":"hidden"}>Withdraw</span>
			</div>	
		
			{(modelContent?.operation_type === 'crediting' && modelContent?.operation_method_type === 'trade')? <TradingAccount openModelFunc={openModelFunc} modelContent={modelContent}/>:''}
			{(modelContent?.operation_type === 'crediting' && modelContent?.operation_method_type === 'deposit')? <DepositAccount openModelFunc={openModelFunc} modelContent={modelContent} />:''}	
			{(modelContent?.operation_type === 'crediting' && modelContent?.operation_method_type === 'withdraw' )? <WithdrawAccount openModelFunc={openModelFunc} modelContent={modelContent}/>:''}
		
			{/**  *  confirmation pops * **/}
			{(modelContent?.operation_type === 'confirm_deposit' )? <ConfirmDeposit openModelFunc={openModelFunc} sideModel="true" modelContent={modelContent} />:''}
			{(modelContent?.operation_type === 'confirm_withdraw' )? <ConfirmWithdraw openModelFunc={openModelFunc} sideModel="true" modelContent={modelContent} />:''}
	

			{/**
			 *  Adding account wallet section
			 * **/}
			{(modelContent?.operation_type === 'account_wallet' )?   <AccountWallet  openModelFunc={openModelFunc} />:''}
			{(modelContent?.operation_type === 'add_bank_account' )? <AddBankAccount modelContent={modelContent} openModelFunc={openModelFunc} sideModel="true" />:''}
			{(modelContent?.operation_type === 'add_phone_number' )? <AddPhoneNumber modelContent={modelContent} openModelFunc={openModelFunc} sideModel="true" />:''}
			
		</div> 
	  </div>
	);
};
export default PopModel;
