import _ from "lodash";
export const formatAmount = (amount) => {
    try{
        amount = parseFloat(amount);
        const locale = Intl.NumberFormat().resolvedOptions().locale;
        const options = { minimumFractionDigits: 0, maximumFractionDigits: 8 };
        const result = `${amount.toLocaleString(locale, options)}`;
        return !_.isNaN(amount) ? result : "";
    } catch(e){
        
        return amount;
    }
};