import { MoreVertical } from "lucide-react";
import "./requests.scss";
import { useOtctrades } from "../../../providers/OtctradesProvider";
const Openorders = () => {
	const { otctrades, isLoadingOtctrades } = useOtctrades();
	return (
		<div className="dashboard_openorders_component">
			{!isLoadingOtctrades && (
				<table className="open_orders_table">
					<thead>
						<tr>
							<th className="flexing_content flex_container">
							   <span>Pair</span>
							</th>
							<th> Amount </th>
							<th> Actual Price </th>
							<th> Date </th>
							<th> Action </th>
							<th> Details </th>
						</tr>
					</thead>
					<tbody>
						{otctrades.map((otctrade) => (
							<tr key={otctrade.id}>
								<td>
									{otctrade.sellCurrency}-{otctrade.buyCurrency}
								</td>
								<td>0.005 BTC</td>
								<td>19,727.16 USD</td>
								<td>21.10.2023 / 16:32 PM</td>
								<td className="action_td_tabs table_width_20">
							    	<div className="flexing_content flex_container text-right mobile_wallet_actions">
									  <button className="list_action_button postive_btn" onClick={() => alert('')}>ACCEPT</button>
									  <button className="list_action_button" onClick={() => alert('')}>REJECT</button>
									</div>
								</td>
								<td className="action_td_tabs table_width_10">
								   <MoreVertical />
								</td>
							</tr>
						))}
					</tbody>
				</table>
			)}
		</div>
	);
};
export default Openorders;
