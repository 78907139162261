import React from "react";
import logo from "../../assets/logo.svg";
import "./sideBar.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faWallet, faStore, faBuilding, faChartBar, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";

const Sidebar = () => {
	return (
		<div className="scratch_sidebar">
			<div className="logo">
				<img src={logo} alt="Company Logo" />
			</div>
			<div className="menu">
				<ul className="sidebar-menu">
					<li className="menu-item">
						<FontAwesomeIcon icon={faHome} />
						<span className="menu-item-name">Dashboard</span>
					</li>
					<li className="menu-item">
						<FontAwesomeIcon icon={faWallet} />
						<span className="menu-item-name">Wallets</span>
					</li>
					<li className="menu-item">
						<FontAwesomeIcon icon={faStore} />
						<span className="menu-item-name">Marketplace</span>
					</li>
					<li className="menu-item">
						<FontAwesomeIcon icon={faBuilding} />
						<span className="menu-item-name">Dealrooms</span>
					</li>
					<li className="menu-item">
						<FontAwesomeIcon icon={faChartBar} />
						<span className="menu-item-name">Reports</span>
					</li>
				</ul>
			</div>

			<div className="cta">
				<FontAwesomeIcon icon={faSignOutAlt} />
				<span className="menu-text">Log out</span>
			</div>
		</div>
	);
};

export default Sidebar;
