import { useQuery } from "@tanstack/react-query";
import { createContext, useContext, useEffect, useReducer, useState } from "react";
import { useAuth } from "./AuthProvider";
import api from "../api";

const PaymentMethodsContext = createContext();
const PaymentMethodsDispatchContext = createContext();

export const PAYMENT_METHODS_SOURCES = {
	MARKETPLACE: "MARKETPLACE",
	OTCTRADE: "OTCTRADE",
};

export const PAYMENT_METHODS_ACTIONS = {
	updateState: "updateState",
	updateStates: "updateStates",
};

export const PaymentMethodsProvider = ({ children }) => {
	const { authTokens, isLoggedIn } = useAuth();
	const [ paymentMethodsRefresh, setPaymentMethodsRefresh] = useState(false)

	const getPaymentMethods = useQuery({
		queryKey: ["paymentMethods"],
		queryFn: () => {
			paymentMethodsDispatch({
				type: "fetchingPaymentMethods",
			});
			const payMethods  = api.getPaymentMethods({ token: authTokens.IdToken });
			return payMethods;
		},
		enabled: false,
	});

	const reloadPaymentMethods = async () => {
		paymentMethodsDispatch({
			type: "updateState",
			key: "isFetchingPaymentMethods",
			value: true,
		});
		const paymentMethodsRefetchResult = await getPaymentMethods.refetch();
		paymentMethodsDispatch({
			type: "updateState",
			key: "isFetchingPaymentMethods",
			value: false,
		});
		const paymentMethods = paymentMethodsRefetchResult.data.data.data.map((datum) => datum.attributes);
		paymentMethodsDispatch({
			type: "updateState",
			key: "paymentMethods",
			value: paymentMethods,
		});
	};

	const getPaymentOptions = useQuery({
		queryKey: ["paymentOptions"],
		queryFn: () => {
			return api.getPaymentOptions({ token: authTokens.IdToken });
		},
		enabled: false,
	});

	const fetchPaymentOptions = async () => {

		paymentMethodsDispatch({ type: PAYMENT_METHODS_ACTIONS.updateState, key: "isFetchingPaymentOptions", value: true });
		const paymentOptionsRefetchResult = await getPaymentOptions.refetch();
		paymentMethodsDispatch({ type: PAYMENT_METHODS_ACTIONS.updateState, key: "isFetchingPaymentOptions", value: false });
		if (paymentOptionsRefetchResult.status == "success") {

			let paymentOptions = paymentOptionsRefetchResult.data.data.data;
			const bankNameToSwiftCode = {};
			paymentOptions = paymentOptions.map((_) => {
				const paymentOption = _.attributes;
				if (`${paymentOption.payment_type}`.toUpperCase() == "BANK") {
					bankNameToSwiftCode[paymentOption.name] = paymentOption.swift_code;
				}
				return paymentOption;
			});
			paymentMethodsDispatch({
				type: PAYMENT_METHODS_ACTIONS.updateStates,
				states: [
					{ key: "paymentOptions", value: paymentOptions },
					{ key: "bankNameToSwiftCode", value: bankNameToSwiftCode },
				],
			});
		}
	};

	const [paymentMethodsState, paymentMethodsDispatch] = useReducer(paymentMethodsReducer, {
		paymentMethods: [],
		reloadPaymentMethods,
		paymentMethodsSource: PAYMENT_METHODS_SOURCES.OTCTRADE,
		paymentMethodsCurrency: "",
		isLoadingPaymentMethods: false,
		paymentOptions: [],
		isFetchingPaymentOptions: false,
		getPaymentOptions,
		fetchPaymentOptions,
		bankNameToSwiftCode: {}

	});

	const getPaymentMethod = ({ currency, type }) => {
		const paymentMethod = paymentMethodsState.paymentMethods.find((w) => w.currency === currency && w.type === type);
		return paymentMethod;
	};

	const getSelectedPaymentMethod = async() => {
		// refetch paymetn methods to update list 
		paymentMethodsState.reloadPaymentMethods;
	};

	//get paymentMethods
	useEffect(() => {
		(async () => {
			// fetch paymentMethods if logged in
			if (isLoggedIn) {
				await refreshingPaymentMethods();
			}
		})();
	}, [isLoggedIn, paymentMethodsRefresh]);


	const refreshingPaymentMethods = async () => {
		const paymentMethodsRefetchResult = await getPaymentMethods.refetch();
		if (paymentMethodsRefetchResult.status == "success") {
			const paymentMethods = paymentMethodsRefetchResult.data.data.data.map((datum) => datum.attributes);
			paymentMethodsDispatch({
									  type: "fetchedPaymentMethods",
									  paymentMethods,
								  });
	    }
	}

	return (
		<PaymentMethodsContext.Provider value={{ ...paymentMethodsState, getPaymentMethod, getSelectedPaymentMethod }}>
			<PaymentMethodsDispatchContext.Provider value={paymentMethodsDispatch}>{children}</PaymentMethodsDispatchContext.Provider>
		</PaymentMethodsContext.Provider>
	);
};

export const usePaymentMethods = () => {
	return useContext(PaymentMethodsContext);
};

export const usePaymentMethodsDispatch = () => {
	return useContext(PaymentMethodsDispatchContext);
};

const paymentMethodsReducer = (paymentMethodsState, action) => {
	switch (action.type) {
		case "setSource":
			return { ...paymentMethodsState, paymentMethodsSource: action.source };
		case "setCurrency":
			return {
				...paymentMethodsState,
				paymentMethodsCurrency: action.currency,
			};
		case "fetchedPaymentMethods":
			return {
				...paymentMethodsState,
				paymentMethods: action.paymentMethods,
				isLoadingPaymentMethods: false,
			};

		case "fetchingPaymentMethods":
			return { ...paymentMethodsState, isLoadingPaymentMethods: true };

		case PAYMENT_METHODS_ACTIONS.updateState: {
			const newState = { ...paymentMethodsState };
			newState[action.key] = action.value;
			return newState;
		}

		case PAYMENT_METHODS_ACTIONS.updateStates: {
			const newState = { ...paymentMethodsState };
			action.states.forEach(({ key, value }) => {
				newState[key] = value;
			});
			return newState;
		}

		default: {
			throw Error("[paymentMethodsReducer] Unknown action: " + action.type);
		}
	}
};
