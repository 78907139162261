import React, { useEffect, useState } from "react";
import "./deposits.scss";
import { useAuth } from "../../../../providers/AuthProvider";
import { useDepositsReport } from "./providers/depositsReportProvider";
import { useReportFilters } from "../../reportsFilterProvider";
import { formatDate } from "../../../../common/formatDate";
import { formatAmount } from "../../../../common/formatAmount";
import { Loader2 } from "lucide-react";
import { useNavigate } from "react-router-dom";
import copyIcon from "../../../../assets/copy.svg";
import moreWalletActionsIcon from "../../../../assets/wallets/more-wallet-actions.svg";

const Deposits = () => {
  const { isLoggedIn } = useAuth();
  const { filters } = useReportFilters();
  const { fetchDepositsReport, isFetchingDepositsReport, depositsReport } = useDepositsReport();
  const navigate = useNavigate();
  const [showSectionBlock, setShowSectionBlock] = useState('');

  // Fetch deposits report if logged in
  useEffect(() => {
    if (isLoggedIn) {
      fetchDepositsReport();
    }
  }, [isLoggedIn, fetchDepositsReport]);

  // Parse date from string and return a Date object
  const parseDate = (dateStr) => {
    if (!dateStr || typeof dateStr !== "string" || dateStr === "Invalid date") return null;

    const [datePart, timePart] = dateStr.split(" - ");
    const [day, month, year] = datePart.split("/").map((num) => parseInt(num, 10));
    const [time, period] = timePart.split(" ");
    const [hours, minutes] = time.split(":").map((num) => parseInt(num, 10));

    let hours24 = hours;
    if (period === "PM" && hours !== 12) hours24 = hours + 12;
    if (period === "AM" && hours === 12) hours24 = 0;

    return new Date(year, month - 1, day, hours24, minutes);
  };

  // Apply filters to the deposits report
  const applyFilters = (deposits) => {
    let filteredDeposits = deposits;

    // Filter by search query
    if (filters.searchQuery) {
      filteredDeposits = filteredDeposits.filter((deposit) =>
        Object.values(deposit)
          .some((val) => val && val.toString().toLowerCase().includes(filters.searchQuery.toLowerCase()))
      );
    }

    // Filter by date range
    if (filters.startDate && filters.endDate) {
      const start = new Date(filters.startDate);
      const end = new Date(filters.endDate);

      if (!isNaN(start) && !isNaN(end)) {
        filteredDeposits = filteredDeposits.filter((deposit) => {
          const depositDate = parseDate(deposit.date);
          return depositDate && depositDate >= start && depositDate <= end;
        });
      }
    }

    return filteredDeposits;
  };

  const filteredDepositsReport = applyFilters(depositsReport);

  // Navigate to transaction details on row click
  const viewTxn = ({ txn }) => {
    const id = txn._rawTxn.id;
    const txnType = txn._rawTxnType;
    const route = txnType === "request" ? `/transactiondetails/request/${id}` : `/transactiondetails/${id}`;
    navigate(route);
  };

  const copyToClipBoard = async (copyText) => {
    try {
      await navigator.clipboard.writeText(copyText);
    } catch (err) {
      console.error("Error copying text: ", err);
    }
  };

  return (
    <div className="deposits_report_body">
      {/* Show loader when fetching deposits report */}
      {isFetchingDepositsReport && (
        <div className="deposits_report_loader_container">
          Loading deposits report...
          <Loader2 className="deposits_report_loader" />
        </div>
      )}

      {/* Show deposits report table when not fetching */}
      {!isFetchingDepositsReport && (
        <table className="report_table deposits_report_table">
          <thead>
            <tr>
              <th>Transaction ID</th>
              <th>Payment Type</th>
              <th>Currency</th>
              <th>Amount</th>
              <th>Status</th>
              <th>Date</th>
              <th>Payment Reference</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            {filteredDepositsReport.map((deposit) => (
              <React.Fragment key={deposit.transactionID}>
                <tr  onClick={() => setShowSectionBlock(prev => (prev === deposit?.id ? "" : deposit?.id))}>
                  <td>{deposit.transactionID}</td>
                  <td>{deposit.paymentType}</td>
                  <td>{deposit.walletCurrency}</td>
                  <td>
                    {formatAmount(parseFloat(deposit.walletTxnAmount))} 
                    <br /> 
                    <span className="help_text">({deposit.walletCurrency})</span>
                  </td>
                  <td>{deposit.status}</td>
                  <td>{formatDate(deposit.createdOn)}</td>
                  <td>
                    <span>{deposit.paymentReference}</span>
                    <img
                      src={copyIcon}
                      alt="copy"
                      className="copy_icon"
                      onClick={(e) => {
                        e.stopPropagation();
                        copyToClipBoard(deposit.paymentReference ?? "");
                      }}
                    />
                  </td>
                  <td className="action_td_tabs">
                    <div
                      className="flexing_content flex_container text-right mobile_wallet_actions hidding_more_actions"
                    >
                      <img
                        src={moreWalletActionsIcon}
                        alt="more actions"
                        className="more_action_icon"
                      />
                    </div>
                  </td>
                </tr>

                {showSectionBlock === deposit?.id && (
                  <tr className="body_expanded_background nopadding">
                    <td colSpan="8" className="nopadding">
                      <table className="details_content_other column_70 nopadding">
                        <tbody>
                          <tr>
                            <td>
                              <div className="details_content_title">Transaction Details</div>
                              <div className="details_content">

                                  <p><b>Status</b> <span>{deposit.status}</span></p>  
                                  <p><b>Date</b> <span>{formatDate(deposit.createdOn)}</span></p>  
                                  <p><b>Source</b> <span>{deposit.paymentType}</span></p>  
                                  <p><b>Currency</b> <span>{deposit.walletCurrency}</span></p>  
                                  <p><b>Deposit amount</b> <span>{formatAmount(parseFloat(deposit.walletTxnAmount))}  <span className="help_text">({deposit.walletCurrency})</span></span></p>  
                                  
                                  <p><b>Network fee</b> <span></span></p> 
                                  <p><b>Network</b> <span></span></p>

                                  <p><b>TxID</b> <span>{deposit.id}</span></p>  
                                  <p><b>Payment Reference</b> <span>{deposit.paymentReference}
                                                                    <img
                                                                      src={copyIcon}
                                                                      alt="copy"
                                                                      className="copy_icon"
                                                                      onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        copyToClipBoard(deposit.paymentReference ?? "");
                                                                      }}
                                                                    /></span></p>  
                              </div>
                            </td>
                          </tr>  
                        </tbody>
                      </table>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Deposits;
