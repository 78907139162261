import React, { useState } from "react";
import { Loader2, Info } from "lucide-react";

import "./payment_methods.scss";
const AddAccountWallet = () => {

	const [selectedTab, setSelectedTab] = useState("my account"); 
	const [selectedAccountDetails, setSelectedAccountDetails] = useState(""); 
	const [selectedAccountMoreDetails, setSelectedAccountMoreDetails] = useState(""); 
	
	// toggle payment types tabs 
    const handleToggleTabs = async (options) => {
		if(options?.payment_type !== ""){
		  setSelectedTab(options?.payment_type)
		}
	} 

	const handleToggleAccountDetails = async (options) => {
		if(options?.account_id !== ""){
			if(selectedAccountDetails === options?.account_id){
				setSelectedAccountDetails("")
			}else{setSelectedAccountDetails(options?.account_id)}
		}
	} 

	const handleToggleAccountMoreDetails = async (options) => {
		if(options?.account_id !== ""){

			if(selectedAccountMoreDetails === options?.account_id){
				setSelectedAccountMoreDetails("")
			}else{setSelectedAccountMoreDetails(options?.account_id)}
		}
	} 

	return (
		<div className="accountsettings_paymentmethods_model">
		  <div className="accountsettings_paymentmethods_model_heading">Add Wallet Address</div>

		  <div className="form_input_field column_100"> 
			<label className="column_100  text-left">Wallet name</label>
			<input className="column_100 input" type="text" placeholder="Wallet name" />
		  </div>
		  <div className="form_input_field column_100"> 
			<label className="column_100  text-left">wallet address</label>
			<input className="column_100 input" type="text" placeholder="Wallet address" />
		  </div>
		  <div className="add_new_button column_100"> 
			<button>Add wallet address</button>
		  </div>

		</div>
	);
};
export default AddAccountWallet;
