import React, { useEffect, useState } from "react";
import "./withdrawals.scss";
import { useAuth } from "../../../../providers/AuthProvider";
import { useWithdrawsReport } from "./providers/withdrawsReportProvider";
import { useReportFilters } from "../../reportsFilterProvider";
import { Loader2 } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../../../common/formatDate";
import { formatAmount } from "../../../../common/formatAmount";
import moreWalletActionsIcon from "../../../../assets/wallets/more-wallet-actions.svg";

const Withdraws = () => {
  const { isLoggedIn } = useAuth();
  const { filters } = useReportFilters();
  const { fetchWithdrawsReport, isFetchingWithdrawsReport, withdrawsReport } = useWithdrawsReport();
  const navigate = useNavigate();
  const [showSectionBlock, setShowSectionBlock] = useState('');

  // Get withdrawals report
  useEffect(() => {
    if (isLoggedIn) {
      fetchWithdrawsReport();
    }
  }, [isLoggedIn, fetchWithdrawsReport]);

  // Parse date from string and return a Date object
  const parseDate = (dateStr) => {
    if (!dateStr || typeof dateStr !== "string" || dateStr === "Invalid date") return null;

    const [datePart, timePart] = dateStr.split(" - ");
    const [day, month, year] = datePart.split("/").map((num) => parseInt(num, 10));
    const [time, period] = timePart.split(" ");
    const [hours, minutes] = time.split(":").map((num) => parseInt(num, 10));

    let hours24 = hours;
    if (period === "PM" && hours !== 12) hours24 = hours + 12;
    if (period === "AM" && hours === 12) hours24 = 0;

    return new Date(year, month - 1, day, hours24, minutes);
  };

  // Apply filters to the withdraws report
  const applyFilters = (withdraws) => {
    let filteredWithdraws = withdraws;

    // Filter by search query
    if (filters.searchQuery) {
      filteredWithdraws = filteredWithdraws.filter((withdraw) =>
        Object.values(withdraw)
          .some((val) => val && val.toString().toLowerCase().includes(filters.searchQuery.toLowerCase()))
      );
    }

    // Filter by date range
    if (filters.startDate && filters.endDate) {
      const start = new Date(filters.startDate);
      const end = new Date(filters.endDate);

      if (!isNaN(start) && !isNaN(end)) {
        filteredWithdraws = filteredWithdraws.filter((withdraw) => {
          const withdrawDate = parseDate(withdraw.date);
          return withdrawDate && withdrawDate >= start && withdrawDate <= end;
        });
      }
    }

    return filteredWithdraws;
  };

  const filteredWithdrawsReport = applyFilters(withdrawsReport);

  // Navigate to transaction details on row click
  const viewTxn = ({ txn }) => {
    const id = txn._rawTxn.id;
    const txnType = txn._rawTxnType;
    const route = txnType === "request" ? `/transactiondetails/request/${id}` : `/transactiondetails/${id}`;
    navigate(route);
  };

  return (
    <div className="withdraws_report_body">
      {/* Show loader when fetching withdraws report */}
      {isFetchingWithdrawsReport && (
        <div className="withdraws_report_loader_container">
          Loading Withdraws report...
          <Loader2 className="withdraws_report_loader" />
        </div>
      )}

      {/* Show withdraws report table when not fetching */}
      {!isFetchingWithdrawsReport && (
        <table className="report_table withdraws_report_table">
          <thead>
            <tr>
              <th>Transaction ID</th>
              <th>Transaction Type</th>
              <th>Payment Type</th>
              <th>Amount</th>
              <th>Status</th>
              <th>Date</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            {filteredWithdrawsReport.map((withdraw) => (
              <React.Fragment key={withdraw.transactionID}>
                <tr onClick={() => setShowSectionBlock(prev => (prev === withdraw?.id ? "" : withdraw?.id))}>
                  <td>{withdraw.requestId}</td>
                  <td>{withdraw.walletTxnType}</td>
                  <td>{withdraw.paymentType}</td>
                  <td>
                    {formatAmount(parseFloat(withdraw.walletTxnAmount))}
                    <br />
                    <span className="help_text">({withdraw.walletCurrency})</span>
                  </td>
                  <td>{withdraw.status}</td>
                  <td>{formatDate(withdraw.createdOn)}</td>
                  <td className="action_td_tabs">
                    <div className="flexing_content flex_container text-right mobile_wallet_actions hidding_more_actions">
                      <img src={moreWalletActionsIcon} alt="withdraw" className="more_action_icon" />
                    </div>
                  </td>
                </tr>

                {showSectionBlock === withdraw?.id && (
                  <tr className="body_expanded_background nopadding">
                  <td colSpan="8" className="nopadding">
                    <table className="details_content_other column_70 nopadding">
                      <tbody>
                        <tr>
                          <td>
                            <div className="details_content_title">Transaction Details</div>
                            <div className="details_content">

                                  <p><b>Status</b> <span>{withdraw.status}</span></p>  
                                  <p><b>Date</b> <span>{formatDate(withdraw.createdOn)}</span></p>  
                                  <p><b>Source</b> <span>{withdraw.walletCurrency} wallet</span></p>  
                                  <p><b>Currency</b> <span>{withdraw.walletCurrency}</span></p>  
                                  <p><b>Withdraw amount</b> <span>{formatAmount(parseFloat(withdraw.walletTxnAmount))} <span className="help_text">({withdraw.walletCurrency})</span></span></p>  
                                  <p><b>Network fee</b> <span></span></p> 
                                  <p><b>Network</b> <span></span></p>  
                                  <p><b>TxID</b> <span>{withdraw.requestId}</span></p>

                                  
                                  <p><b>Transaction Type</b> <span>{withdraw.walletTxnType}</span></p>
                                  <p><b>Payment Type</b> <span>{withdraw.paymentType}</span></p>
                                  <p><b>{withdraw.paymentType.toLowerCase()} details </b> <span></span></p>    
                                  
                            </div>
                          </td>
                        </tr>  
                      </tbody>
                    </table>
                  </td>
                </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Withdraws;
