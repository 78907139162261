import React, { useState } from "react";
import { Loader2, Info } from "lucide-react";
import PopModel          from "../../../components/models/model";
import moreDetailsIcon   from "../../../assets/settings/more_details.svg";
import editAccountIcon   from "../../../assets/settings/edit_details.svg";
import deleteAccountIcon from "../../../assets/settings/delete_details.svg";
import addedAccountIcon  from "../../../assets/settings/added_success_check.svg";
import showDetailsIcon   from "../../../assets/settings/show_details.svg";
import "./paymentmethods.scss";

const Paymentmethods = () => {

	const [selectedTab, setSelectedTab] = useState("my account"); 
	const [selectedAccountDetails, setSelectedAccountDetails] = useState(""); 
	const [selectedAccountMoreDetails, setSelectedAccountMoreDetails] = useState(""); 
	const [modelStatus, setModelStatus] = useState(false); 
	const [dataModelContent, setModelDataContent] = useState({}); 
	const [dataResponse, setDataResponse] = useState({}); 

	// toggle payment types tabs 
    const handleToggleTabs = async (options) => {
		if(options?.payment_type !== ""){
		  setSelectedTab(options?.payment_type)
		}
	} 

	const handleToggleAccountDetails = async (options) => {
		if(options?.account_id !== ""){
			if(selectedAccountDetails === options?.account_id){
				setSelectedAccountDetails("")
			}else{setSelectedAccountDetails(options?.account_id)}
		}
	} 
											
	const handleToggleAccountMoreDetails = async (options) => {
		if(options?.account_id !== ""){
			if(selectedAccountMoreDetails === options?.account_id){
				setSelectedAccountMoreDetails("")
			}else{setSelectedAccountMoreDetails(options?.account_id)}
		}
	} 

	const showModel = (options) => {
	   if(options.operation_type !== ""){
		
		  // close all drop downs 
	      setSelectedAccountMoreDetails("")
	      setSelectedAccountDetails("")
		  setModelDataContent(options)
		  setModelStatus(true);
	   }
	}
	
	const modelStatusTrack = (e) => {
        setModelStatus(false);
	}

	return (
		<div className="accountsettings_paymentmethods_page">
			<PopModel openModelFunc={(e) => modelStatusTrack(e)} openModel={modelStatus} modelContent={dataModelContent} modeCloseResponse={dataResponse} ></PopModel> 
			<div className="paymentmethods_heading">Payment methods</div>


			<div className="paymentmethods_tabs">
        		<div className="payment_methods_tabs_header">
				  <div role="my_accounts" className={(selectedTab === 'my account')? "payment_methods_tabs_header_tab_main active_tab_bg":"payment_methods_tabs_header_tab_main"} onClick={() => handleToggleTabs({payment_type: 'my account'})}>
					<div className={(selectedTab === 'my account')? "payment_methods_tabs_header_tab active_tab":"payment_methods_tabs_header_tab"}>
					  <span className="payment_methods_tabs_header_tab_text">My accounts</span>
					</div>
				  </div>
				  <div role="3rd_party_account" className={(selectedTab === '3rd party accounts')? "payment_methods_tabs_header_tab_main drag_left_20 active_tab_bg":"payment_methods_tabs_header_tab_main drag_left_20"} onClick={() => handleToggleTabs({payment_type: '3rd party accounts'})}>
					<div className={(selectedTab === '3rd party accounts')? "payment_methods_tabs_header_tab active_tab":"payment_methods_tabs_header_tab"}>
					  <span className="payment_methods_tabs_header_tab_text">3rd party accounts</span>
					</div>
				  </div>
				</div>


				<div className={(selectedTab === 'my account')? "payment_methods_tabs_body": "hidden"}>				  
				  <div className="payment_methods_tabs_body_card">
					<div className="payment_methods_tabs_body_card_content"> 
				      <h2>Bank account</h2>	
					  <div className="listing_account_details_group">

					    <div className="listing_account_details_group_content flex_container flexing_content">
						   <div className="column_10">
							 <img src={addedAccountIcon} alt="Ziraat Bankası" className="added_bank_account"/>
						   </div>
						   <div className="column_20">
							 <h3> The Bank</h3>
							 <p> Ziraat Bankası </p>
						   </div>
						   <div className="column_30">
							 <h3> Bank account number </h3>
							 <p> DE89370400440532013000 </p>
						   </div>
						   <div className="column_30">
							 <h3> Name </h3>
							 <p> Sarah Melbourne </p>
						   </div>
						   <div className="column_10 position_relative">
							 
							 <img src={moreDetailsIcon} alt="Ziraat Bankası" className="added_bank_account" 
							      onClick={() => handleToggleAccountMoreDetails({account_id: 1})}/>

							 <div className={(selectedAccountMoreDetails === 1)? "account_details_pop":"account_details_pop hidden"}>
                               <article className="clearfix" onClick={() => showModel({operation_type: 'add_bank_account'})}>
								 <span>Edit</span>
								 <img src={editAccountIcon} alt="Edit" className="added_bank_account"/>
							   </article>
							   <article className="clearfix" onClick={() => showModel({operation_type: 'delete_bank_account'})}>
								 <span>Delete</span>
								 <img src={deleteAccountIcon} alt="Delete" className="added_bank_account"/>
							   </article>

							 </div>
						   </div>
						   <div className={(selectedAccountDetails === 1)? "column_100": "column_100 hidden"}>
							 <div className="flex_container flexing_content">
							   <div className="column_30">
									<h3> Currency </h3>
									<p> Sarah Melbourne </p>
							   </div>
							   <div className="column_30">
								    <h3> City </h3>
									<p> Sarah Melbourne </p>
									<h3> State/Region </h3>
									<p> Sarah Melbourne </p>
							   </div>
							   <div className="column_40">
								
							        <h3> Building Number </h3>
									<p> Sarah Melbourne </p>
									<h3> Email </h3>
									<p> Sarah Melbourne </p>
							   </div>
							 </div>
						   </div>

						   <div className="column_100 text-right show_details_section" onClick={() => handleToggleAccountDetails({account_id: 1})}>
							 <span>Show details</span>  
							 <img src={showDetailsIcon} alt="show details" className={(selectedAccountDetails === 1)? "show_details_section_icon twisted_360_2": "show_details_section_icon"} />
						   </div>

						</div> 
						
					  </div> 
					</div>  
                    <div className="flex_container flexing_content">
					  <div className="payment_methods_tabs_body_card_content column_70"> 
						<p>Add your bank account, this is where money you receive will be paid to</p>	 
					  </div>
					  <div className="payment_methods_tabs_body_card_btn column_30"> 
						<button className="add_new_button"  onClick={() => showModel({operation_type: 'add_bank_account'})}>Add bank account</button>
					  </div>
					</div> 						
                  </div>

				  <div className="payment_methods_tabs_body_card">
					<div className="payment_methods_tabs_body_card_content"> 
				      <h2>Mobile money number</h2>		

					  <div className="listing_phone_details_group">
					    <h3>Mobile number</h3>

						<div className="listing_phone_details_group_content flex_container flexing_content"> 
						  <div className="column_60">
							<p> +256 776439250 </p>
						  </div>
						  <div className="column_10 position_relative">
							<img src={moreDetailsIcon} alt="Ziraat Bankası" className="added_bank_account" onClick={() => handleToggleAccountMoreDetails({account_id: 11})} />
							<div className={(selectedAccountMoreDetails === 11)? "account_details_pop":"account_details_pop hidden"}>
                               <article className="clearfix" onClick={() => showModel({operation_type: 'add_phone_number'})}>
								 <span>Edit</span>
								 <img src={editAccountIcon} alt="Edit" className="added_bank_account"/>
							   </article>
							   <article className="clearfix" onClick={() => showModel({operation_type: 'delete_phone_number'})}>
								 <span>Delete</span>
								 <img src={deleteAccountIcon} alt="Delete" className="added_bank_account"/>
							   </article>

							 </div>
						  </div> 	
                        </div>
					  </div>
                    </div>
				
                    <div className="flex_container flexing_content">
					  <div className="payment_methods_tabs_body_card_content column_70 clearfix">  
						<p>Add your mobile money number, this is where money you receive will be paid to</p>	 
				   	  </div>
					  <div className="payment_methods_tabs_body_card_btn column_30"> 
						<button className="add_new_button" onClick={() => showModel({operation_type: 'add_phone_number'})}>Add phone number</button>
					  </div>
 					</div>
   				  </div>

				  <div className="payment_methods_tabs_body_card flex_container flexing_content full_width">
					<div className="payment_methods_tabs_body_card_content full_width"> 
				      <h2>Address management</h2>	
					  <div className="listing_phone_details_group full_width">
					    <h3>Wallet 1</h3>
						<div className="listing_phone_details_group_content flex_container flexing_content"> 
						  <div className="column_60">
							<p> mqtWRXtVySmX1en5XSDMumVzdrXc4tqzZR </p>
						  </div>
						  <div className="column_10 position_relative">

							 <img src={moreDetailsIcon} alt="" className="added_bank_account" onClick={() => handleToggleAccountMoreDetails({account_id: 10})} />
							 <div className={(selectedAccountMoreDetails === 10)? "account_details_pop":"account_details_pop hidden"}>
                               <article className="clearfix" onClick={() => showModel({operation_type: 'add_wallet_address'})}>
								 <span>Edit</span>
								 <img src={editAccountIcon} alt="Edit" className="added_bank_account"/>
							   </article>
							   <article className="clearfix" onClick={() => showModel({operation_type: 'delete_wallet_address'})}>
								 <span>Delete</span>
								 <img src={deleteAccountIcon} alt="Delete" className="added_bank_account"/>
							   </article>

							 </div>
						  </div> 	
                        </div>
					  </div>
					</div>  	

					<div className="payment_methods_tabs_body_card_content column_100 clearfix"></div>
					
					<div className="payment_methods_tabs_body_card_content column_70">  
					  <p>Add your wallet addresses, the addresses will be used for tokens withdraw</p>	 
					</div>
					<div className="payment_methods_tabs_body_card_btn column_30"> 
					  <button className="add_new_button" onClick={() => showModel({operation_type: 'add_wallet_address'})}>Add wallet address</button>
					</div>
                  </div>

				</div>




				<div className={(selectedTab === '3rd party accounts')? "payment_methods_tabs_body": "hidden"}>


				</div>

			</div>
		</div>

	);
};
export default Paymentmethods;
