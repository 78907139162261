import React from "react";
import "./account_settings_general.scss";
import Sidebar from "../sideBar/sideBar";
import Header from "../../header/header";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBell} from '@fortawesome/free-solid-svg-icons';
import profileImage from '../../assets/profile-pic.jpeg';

const SettingsGeneral = () => {
  return (
    <>
    
      <Sidebar/>
      <Header/>
      <div className="settings_page">
        <div className="container">
            <div className="settings_header">
                <div className="left-section">
                    <span>Settings</span>
                </div>
                <div className="right-section">
                    <FontAwesomeIcon icon={faBell}/>
                </div>
            </div>
            <div className="settings_body">
              <div className="menu">
                <ul className="settings_menu">
                  <li className="menu-item">My Account</li>
                  <li className="menu-item">My teams</li>
                  <li className="menu-item">Identity verification</li>
                  <li className="menu-item">Trading settings </li>
                  <li className="menu-item">Password and security</li>
                </ul>
              </div>

              <div className="settings_widget">
                <div className="general">
                  <div className="header">
                    <div className="left-section">
                        <span className="header-text">General</span>
                    </div>
                    <div className="right-section">
                        <div className="btn-box">
                            <div className="save-btn">Save</div>
                            <div className="cancel-btn">Cancel</div>
                        </div>
                    </div>
                  </div>
                  <div className="data-display">
                    <div className="data-group">
                      <div className="profile-picture">
                        <img src={profileImage} alt="profile" />
                      </div>
                      <p>Change photo</p>
                    </div>
                    <div className="data-group">
                        <div className="main-input-group">
                            <div className="data-input-group">
                                <label className="general-settings-input">
                                    <span className="general-settings-input-label">
                                    Username
                                    </span>
                                    <input placeholder="Sarah M" name="username" />
                                </label>
                                <div className="spacer"></div>
                                <label className="general-settings-input">
                                    <span className="general-settings-input-label">
                                    First name
                                    </span>
                                    <input placeholder="Sarah" name="firstname" />
                                </label>
                                <div className="spacer"></div>
                                <label className="general-settings-input">
                                    <span className="general-settings-input-label">
                                    Last name
                                    </span>
                                    <input placeholder="Melbourne" name="lastname" />
                                </label>
                                <div className="spacer"></div>
                                <label className="general-settings-input">
                                    <span className="general-settings-input-label">
                                    Gender
                                    </span>
                                    <select name="gender" id="gender">
                                        <option value="male">Male</option>
                                        <option value="female" selected>Female</option>
                                    </select>
                                </label>
                            </div>

                            <div className="data-input-group">
                                <label className="general-settings-input">
                                    <span className="general-settings-input-label">
                                    Email
                                    </span>
                                    <input placeholder="sarah.melbourne@gmail.com" name="email" />
                                </label>
                                <div className="spacer"></div>
                                <label className="general-settings-input">
                                    <span className="general-settings-input-label">
                                    Phone
                                    </span>
                                    <input placeholder="+441234567890" name="phone" />
                                </label>
                                <div className="spacer"></div>
                                <label className="general-settings-input">
                                    <span className="general-settings-input-label">
                                    Date of Birth
                                    </span>
                                    <input placeholder="11/11/1987" name="date-of-birth" />
                                </label>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
                <div className="location">
                  <div className="header">
                    <span className="header-text">Location</span>
                  </div>
                  <div className="data-display">
                    <div className="data-group">
                      <h4>Address</h4>
                      <p>Canada water str. 194/1</p>
                      <p>London, SE16 7ET</p>
                      <p>United Kingdom</p>
                    </div>
                    <div className="data-group">
                      <h4>Time zone</h4>
                      <p>UTC+02:00 Berlin, Stockholm, Rome, Bern, Brussels</p>
                    </div>
                  </div>
                </div>
                <div className="additional_accounts">
                  <div className="header">
                    <span className="header-text">Additional accounts</span>
                  </div>
                  <div className="data-display">
                    <div className="data-group">
                      <p>Creating company account allows you to use Muda in different ways, while still<br/>
                        having just one login. Manage teams and company transfers from one common <br/> company account.
                      </p>
                    </div>
                    <div className="create-acct-btn-box">
                      <div className="create-acct-btn">Create company account</div>
                    </div>
                  </div>
                </div>
                <div className="widget-footer">
                    <span>Close my account</span>
                </div>
              </div>
            </div>
            
        </div>
        
      </div>
    </>
  );
}

export default SettingsGeneral;
