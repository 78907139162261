import _ from "lodash";
export const formatDate = (dateString) => {
   
    try{

        const date = new Date(dateString);
        const optionsDate = {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        };
        const formattedDate = new Intl.DateTimeFormat('en-GB', optionsDate).format(date);
        
        const optionsTime = {
          hour: '2-digit',
          minute: '2-digit',
          hour12: true
        };
        const formattedTime = new Intl.DateTimeFormat('en-GB', optionsTime).format(date);
        return `${formattedDate.replace(/\//g, '.')} / ${formattedTime}`;

    } catch(e){       
           
        return dateString;
    }
};