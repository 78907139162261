import { useState } from "react";
import cn from "classnames";
import "./transactionsHistory.scss";
import Swaps from "./swaps/swaps";
import Deposits from "./deposits/deposits";
import Withdrawals from "./withdrawals/withdrawals";
import { useFeatureFlags } from "../../../providers/FeatureFlags/FeatureFlagsProvider";

const TransactionsHistory = () => {
	const { isFeatureFlagEnabled } = useFeatureFlags();
	const TRANSACTIONS_HISTORY_TABS = {
		DEPOSITS: "DEPOSITS",
		WITHDRAWALS: "WITHDRAWALS",
		SWAPS: "SWAPS",
	};

	const emptyTransactionsHistoryState = {
		activeTab: TRANSACTIONS_HISTORY_TABS.DEPOSITS,
	};

	const [transactionsHistoryState, setTransactionsHistoryState] = useState(emptyTransactionsHistoryState);

	const switchToTab = (tab) => {
		const newTransactionsHistoryState = { ...transactionsHistoryState };
		newTransactionsHistoryState.activeTab = tab;
		setTransactionsHistoryState(newTransactionsHistoryState);
	};

	return (
		<div id="transactions_history_report_component">
			<div className="transactions_history_report_tab_list">
				{/* {isFeatureFlagEnabled("deposits") && (
					<div
						className={cn("tab deposits", { active: transactionsHistoryState.activeTab == TRANSACTIONS_HISTORY_TABS.DEPOSITS })}
						onClick={() => {
							switchToTab(TRANSACTIONS_HISTORY_TABS.DEPOSITS);
						}}>
						Deposits
					</div>
				)}
				{isFeatureFlagEnabled("withdrawals") && (
					<div
						className={cn("tab withdrawals", { active: transactionsHistoryState.activeTab == TRANSACTIONS_HISTORY_TABS.WITHDRAWALS })}
						onClick={() => {
							switchToTab(TRANSACTIONS_HISTORY_TABS.WITHDRAWALS);
						}}>
						Withrawals
					</div>
				)} */}

                <div
						className={cn("tab deposits", { active: transactionsHistoryState.activeTab == TRANSACTIONS_HISTORY_TABS.DEPOSITS })}
						onClick={() => {
							switchToTab(TRANSACTIONS_HISTORY_TABS.DEPOSITS);
						}}>
						Deposits
				</div>
                <div
						className={cn("tab withdrawals", { active: transactionsHistoryState.activeTab == TRANSACTIONS_HISTORY_TABS.WITHDRAWALS })}
						onClick={() => {
							switchToTab(TRANSACTIONS_HISTORY_TABS.WITHDRAWALS);
						}}>
						Withdrawals
				</div>
				<div
					className={cn("tab swaps", { active: transactionsHistoryState.activeTab == TRANSACTIONS_HISTORY_TABS.SWAPS })}
					onClick={() => {
						switchToTab(TRANSACTIONS_HISTORY_TABS.SWAPS);
					}}>
					Swaps
				</div>
			</div>


			{transactionsHistoryState.activeTab == TRANSACTIONS_HISTORY_TABS.DEPOSITS && <Deposits />}
			{transactionsHistoryState.activeTab == TRANSACTIONS_HISTORY_TABS.WITHDRAWALS && <Withdrawals />}
			{transactionsHistoryState.activeTab == TRANSACTIONS_HISTORY_TABS.SWAPS && <Swaps />}
		</div>
	);
};
export default TransactionsHistory;
