import { useQuery } from "@tanstack/react-query";
import { createContext, useContext, useReducer } from "react";
import { useAuth } from "../../../../../providers/AuthProvider";
import api from "../../../../../api";
import _ from "lodash";
import moment from "moment";
import Big from "big.js";
import { formatAmount } from "../../../../../common/formatAmount";

// Contexts for deposits report state and dispatch
const DepositsReportContext = createContext();
const DepositsReportDispatchContext = createContext();

// Action types for the reducer
export const DEPOSITS_REPORT_ACTIONS = {
  UPDATE_STATE: "updateState",
  UPDATE_STATES: "updateStates",
};

// Provider component for managing the deposits report state
export const DepositsReportProvider = ({ children }) => {
  const { authTokens } = useAuth();

  // Query for fetching the deposits report from API
  const { data, status, refetch } = useQuery({
    queryKey: ["depositsReport"],
    queryFn: () => api.getDepositsReport({ token: authTokens.IdToken }),
    enabled: false, // Query is disabled until triggered manually
  });

  // Function to fetch and update the deposits report state
  const fetchDepositsReport = async () => {

    depositsReportDispatch({ type: DEPOSITS_REPORT_ACTIONS.UPDATE_STATE, key: "isFetchingDepositsReport", value: true });

    const result = await refetch();
    depositsReportDispatch({ type: DEPOSITS_REPORT_ACTIONS.UPDATE_STATE, key: "isFetchingDepositsReport", value: false });
    if (result.status === "success") {
      let depositsReport = result.data.data.data.map((item) => {
							
		                    const deposit = item.attributes;
							      deposit.transactionID = deposit.id.split("-")[0];
							return deposit;
						});
       depositsReportDispatch({ type: DEPOSITS_REPORT_ACTIONS.UPDATE_STATE, key: "depositsReport", value: depositsReport });
    }
  };

  // State and dispatch via useReducer
  const [depositsReportState, depositsReportDispatch] = useReducer(DepositsReportReducer, {
    depositsReport: [],
    isFetchingDepositsReport: false,
    fetchDepositsReport,
    getDepositsReport: { data, status, refetch },
  });

  return (
    <DepositsReportContext.Provider value={depositsReportState}>
      <DepositsReportDispatchContext.Provider value={depositsReportDispatch}>
        {children}
      </DepositsReportDispatchContext.Provider>
    </DepositsReportContext.Provider>
  );
};

// Custom hook to access deposits report state
export const useDepositsReport = () => useContext(DepositsReportContext);

// Custom hook to access deposits report dispatch
export const useDepositsReportDispatch = () => useContext(DepositsReportDispatchContext);

// Reducer to manage deposits report state updates
const DepositsReportReducer = (state, action) => {
  switch (action.type) {
    case DEPOSITS_REPORT_ACTIONS.UPDATE_STATE: {
      return {
        ...state,
        [action.key]: action.value,
      };
    }
    case DEPOSITS_REPORT_ACTIONS.UPDATE_STATES: {
      return {
        ...state,
        ...action.states.reduce((acc, { key, value }) => {
          acc[key] = value;
          return acc;
        }, {}),
      };
    }
    default:
      throw new Error(`[DepositsReportReducer] Unknown action: ${action.type}`);
  }
};
