import React, { useState } from "react";
import { Loader2, Info } from "lucide-react";
import "../../model.scss";
import Select from 'react-select';

import MarketTrading from "./content/market";
import OTCTrading from "./content/otc";

const TradingAccount = () => {

	const [selectedTab, setSelectedTab] = useState("market"); 
	const [selectedAccountDetails, setSelectedAccountDetails] = useState(""); 
	const [selectedAccountMoreDetails, setSelectedAccountMoreDetails] = useState(""); 
	const [marketplaceState, setMarketplaceState] = useState({
		currencyOptions: [{label: 'ETH', value: '1'}, {label: 'USDT', value: '2'}, {label: 'BTC', value: '3'}],
		buyCurrencyFilter: [],
		sellCurrencyFilter: []
	});
	

	// toggle payment types tabs 
    const handleToggleTabs = async (options) => {
		if(options?.payment_type !== ""){
		  setSelectedTab(options?.payment_type)
		}
	} 

	const handleToggleAccountDetails = async (options) => {
		if(options?.account_id !== ""){
			if(selectedAccountDetails === options?.account_id){
				setSelectedAccountDetails("")
			}else{setSelectedAccountDetails(options?.account_id)}
		}
	} 

	const handleToggleAccountMoreDetails = async (options) => {
		if(options?.account_id !== ""){

			if(selectedAccountMoreDetails === options?.account_id){
				setSelectedAccountMoreDetails("")
			}else{setSelectedAccountMoreDetails(options?.account_id)}
		}
	} 

	
	return (

	  <div className="pop_moddule_pops_model_main">

	    <div className="pop_moddule_pops_model">
          <div className="payment_methods_tabs_header">
			<div role="my_accounts" className={(selectedTab === 'market')? "payment_methods_tabs_header_tab_main active_tab_bg":"payment_methods_tabs_header_tab_main"} onClick={() => handleToggleTabs({payment_type: 'market'})}>
			  <div className={(selectedTab === 'market')? "payment_methods_tabs_header_tab active_tab":"payment_methods_tabs_header_tab"}>
				<span className="payment_methods_tabs_header_tab_text">Market</span>
			  </div>
			</div>
			<div role="3rd_party_account" className={(selectedTab === 'otc')? "payment_methods_tabs_header_tab_main drag_left_20 active_tab_bg":"payment_methods_tabs_header_tab_main drag_left_20"} onClick={() => handleToggleTabs({payment_type: 'otc'})}>
			  <div className={(selectedTab === 'otc')? "payment_methods_tabs_header_tab active_tab":"payment_methods_tabs_header_tab"}>
				<span className="payment_methods_tabs_header_tab_text">OTC</span>
			  </div>
			</div>
	      </div>
	    </div>	
	    <div className="pop_model_section">

          {(selectedTab === 'market')? <MarketTrading />:''}
          {(selectedTab === 'otc')? <OTCTrading /> :''}
          
		  {/* 
          <div className="market_trading">
		    <div className="input_block form_input_field">
			  <label>Buy</label>	
			  <Select value={""} className="input_select" onChange={changeDefaultCurreny}
                      options={marketplaceState.currencyOptions} isSearchable="true" />
			  <input className="column_100 input" type="text" placeholder="what i am buying" />
			</div>

			<div className="input_block form_input_field">
			  <label>Sell</label>	
			  <Select value={""} className="input_select" onChange={changeDefaultCurreny}
                    options={marketplaceState.currencyOptions} isSearchable="true" />
			  <input className="column_100 input" type="text" placeholder="what i am selling" />
			</div>
			<div className="input_block_details">
			   <div>
			     <p>Total balance:  <span>14.63 USDT</span></p>	
			   </div>
			   <div className="add_new_button column_100"> 
			     <button>Place order</button>
		       </div>
			</div>

		  </div>  */}
		    

		</div>  

	  </div>
	);
};
export default TradingAccount;
