import ReactSelect from "react-select";
import {
  TRANSACT_PAGES,
  useTransact,
  useTransactDispatch,
} from "../../providers/TransactProvider";
import "./configurepaymentmethods.scss";
import { Plus, X, Info } from "lucide-react";
import {
  PAYMENT_METHODS_SOURCES,
  usePaymentMethods,
} from "../../../../providers/PaymentMethodsProvider";
import { useEffect, useState } from "react";
import { reactSelectStyle } from "../../../../reactselectstyles/reactselectstyles";
import {
  CREATE_REQUEST_ACTIONS,
  useCreateRequest,
  useCreateRequestDispatch,
} from "../../../transactionDetails/createRequest/providers/createRequestProvider";
import { useForm } from "react-hook-form";

export const PAYMENT_METHOD_TYPES = {
  BANK_ACCOUNT: "BANK_ACCOUNT",
  PHONE_NUMBER: "PHONE_NUMBER",
  WALLET_ADDRESS: "WALLET_ADDRESS",
};

const ConfigurePaymentMethods = () => {
  const { otcTradeState } = useTransact();
  const transactDispatch = useTransactDispatch();
  const createRequestDispatch = useCreateRequestDispatch();
  const [paymentMethodError, setPaymentMethodError] = useState("");
  const { request, order } = useCreateRequest();
  const { paymentMethods, isLoadingPaymentMethods, paymentMethodsSource } =
    usePaymentMethods();
  const [configurePaymentMethodsState, setConfigurePaymentMethodsState] =
    useState({
      paymentMethods: [
        {
          label: "",
          options: [],
        },
      ],
      selectedPaymentMethods: [],
      defineDepositAmounts: false,
      depositAmounts: [],
    });

  const buildPaymentMethodsOptions = () => {
    const newConfigurePaymentMethodsState = { ...configurePaymentMethodsState };
    const PaymentMethodsOptions = {};

    paymentMethods.forEach((paymentMethod) => {
      switch (paymentMethod.type) {
        case PAYMENT_METHOD_TYPES.BANK_ACCOUNT:
          PaymentMethodsOptions["Bank"] = PaymentMethodsOptions["Bank"] || {
            label: "Bank",
            options: [],
          };
          PaymentMethodsOptions["Bank"].options.push({
            label: `${paymentMethod.bankName} : ${paymentMethod.accountNumber}`,
            value: paymentMethod,
          });
          break;
        case PAYMENT_METHOD_TYPES.PHONE_NUMBER:
          PaymentMethodsOptions["Phonenumber"] = PaymentMethodsOptions[
            "Phonenumber"
          ] || { label: "Phonenumber", options: [] };
          PaymentMethodsOptions["Phonenumber"].options.push({
            label: `${paymentMethod.phonenumberLabel} : ${paymentMethod.phonenumber}`,
            value: paymentMethod,
          });
          break;
        case PAYMENT_METHOD_TYPES.WALLET_ADDRESS:
          PaymentMethodsOptions["Wallet Address"] = PaymentMethodsOptions[
            "Wallet Address"
          ] || { label: "Wallet Address", options: [] };
          PaymentMethodsOptions["Wallet Address"].options.push({
            label: `${paymentMethod.walletAddressLabel} : ${paymentMethod.walletAddress}`,
            value: paymentMethod,
          });
          break;
      }
    });

    newConfigurePaymentMethodsState.paymentMethods = [];
    
    Object.keys(PaymentMethodsOptions).forEach((key) => {
      const paymentMethodOption = PaymentMethodsOptions[key];
      newConfigurePaymentMethodsState.paymentMethods.push(paymentMethodOption);
    });

    setConfigurePaymentMethodsState(newConfigurePaymentMethodsState);
  };

  useEffect(() => {
    buildPaymentMethodsOptions();
  }, [isLoadingPaymentMethods]);

  const backToOtcTrade = () => {
    transactDispatch({
      type: "goToOtcTrade",
    });
  };

  const addPaymentMethod = () => {
    transactDispatch({
      type: "goToAddPaymentMethod",
    });
    createRequestDispatch({
      type: "goToAddPaymentMethod",
    });
  };
  const handleDepositAmountChange = (idx, value) => {
    const newDepositAmounts = [...configurePaymentMethodsState.depositAmounts];

    newDepositAmounts[idx].instruction = value;
    setConfigurePaymentMethodsState((prevState) => ({
      ...prevState,
      depositAmounts: newDepositAmounts,
    }));
  };

  const goToConfirmOtcTrade = () => {
    const newRequest = { ...request };
    const selectedPaymentMethods =
      configurePaymentMethodsState.selectedPaymentMethods;
    if (selectedPaymentMethods.length === 0) {
      setPaymentMethodError(
        "Please choose a payment method or add a new one to proceed"
      );
      return;
    }
    setPaymentMethodError("");
    const paymentMethods =
      configurePaymentMethodsState.selectedPaymentMethods.map((pm) => {
        return pm.value;
      });
    newRequest.depositAmounts = configurePaymentMethodsState.depositAmounts;
    newRequest.paymentMethods = paymentMethods;
    createRequestDispatch({
      type: CREATE_REQUEST_ACTIONS.updateState,
      key: "request",
      value: newRequest,
    });
    createRequestDispatch({
      type: CREATE_REQUEST_ACTIONS.goToConfirmCreateRequest,
    });
    transactDispatch({
      type: "goToConfirmOtcTrade",
    });
  };

  const goToConfirmRequest = () => {
    const newRequest = { ...request };
    const paymentMethods =
      configurePaymentMethodsState.selectedPaymentMethods.map((pm) => {
        return pm.value;
      });
    newRequest.depositAmounts = configurePaymentMethodsState.depositAmounts;
    newRequest.paymentMethods = paymentMethods;
    createRequestDispatch({
      type: CREATE_REQUEST_ACTIONS.updateState,
      key: "request",
      value: newRequest,
    });
    createRequestDispatch({
      type: CREATE_REQUEST_ACTIONS.goToConfirmCreateRequest,
    });
  };

  const inputChanged = ({ inputName, inputValue }) => {
    const newConfigurePaymentMethodsState = { ...configurePaymentMethodsState };
    newConfigurePaymentMethodsState[inputName] = inputValue;
    setConfigurePaymentMethodsState(newConfigurePaymentMethodsState);
  };

  const inputsChanged = (inputs) => {
    const newConfigurePaymentMethodsState = { ...configurePaymentMethodsState };
    inputs.forEach(({ inputName, inputValue }) => {
      newConfigurePaymentMethodsState[inputName] = inputValue;
    });
    setConfigurePaymentMethodsState(newConfigurePaymentMethodsState);
  };

  const toggleDefineDepositAmounts = () => {
    const depositAmounts = [];
    configurePaymentMethodsState.selectedPaymentMethods.forEach(
      (paymentMethod) => {
        depositAmounts.push({
          paymentMethod,
          instruction: "",
        });
      }
    );

    const newDefineDepositAmounts =
      !configurePaymentMethodsState.defineDepositAmounts;
    inputsChanged([
      { inputName: "depositAmounts", inputValue: depositAmounts },
      {
        inputName: "defineDepositAmounts",
        inputValue: newDefineDepositAmounts,
      },
    ]);
  };

  const allOptions = configurePaymentMethodsState.paymentMethods.flatMap(
    (paymentGroup) => paymentGroup.options
  );

  /// Filter options based on currency and type
  const filteredOptions = allOptions.filter((option) => {
    // Determine the currency: use otcTradeState currency if available, otherwise fall back to order.pair
    const currency =
      otcTradeState?.buy?.currency || order?.pair?.split(" - ")[1];

    // Check if the option's currency matches
    return option.value.currency === currency;
  });

  return (
    <div id="configurepaymentmethods_component">
      <div className="configurepaymentmethods_controls">
        {paymentMethodsSource == PAYMENT_METHODS_SOURCES.OTCTRADE && (
          <svg
            className="configurepaymentmethods_controls_back"
            onClick={backToOtcTrade}
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M16 4L8 12L16 20"
              stroke="#172C50"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}

        {paymentMethodsSource == PAYMENT_METHODS_SOURCES.MARKETPLACE && (
          <svg
            className="transact_control close"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M19 19L5 5M19 5L5 19"
              stroke="#172C50"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
      </div>
      <div className="configurepaymentmethods_header">
        {paymentMethodsSource == PAYMENT_METHODS_SOURCES.MARKETPLACE && (
          <p>CREATE NEW REQUEST</p>
        )}
        {paymentMethodsSource.includes("otctrade") && <p>PLACE NEW ORDER</p>}
      </div>
      <div className="scroll">
        <div className="configurepaymentmethods_details">
          {paymentMethodsSource == PAYMENT_METHODS_SOURCES.MARKETPLACE && (
            <div className="configurepaymentmethods_details_title">
              Payment methods ({order.pair.split(" - ")[1]}){" "}
            </div>
          )}
          {paymentMethodsSource == PAYMENT_METHODS_SOURCES.OTCTRADE && (
            <div className="configurepaymentmethods_details_title">
              Payment methods ({otcTradeState?.buy?.currency})
            </div>
          )}

          <div className="configurepaymentmethods_details_paymentmethods">
            <div className="configurepaymentmethods_details_paymentmethods_title">
              Payment methods
            </div>
            <ReactSelect
              styles={reactSelectStyle}
              closeMenuOnSelect={false}
              isMulti
              value={configurePaymentMethodsState.selectedPaymentMethods}
              options={filteredOptions}
              onChange={(evt) => {
                inputChanged({
                  inputName: "selectedPaymentMethods",
                  inputValue: evt,
                });
              }}
            />
            {/* Display error message */}
            {paymentMethodError && (
              <span className="payment_method_select_error">
                <Info className="payment_method_select_error_icon" />
                {paymentMethodError}
              </span>
            )}
          </div>
          <div className="configurepaymentmethods_details_depositamounts">
            <div className="configurepaymentmethods_details_depositamounts_title">
              <input
                type="checkbox"
                checked={configurePaymentMethodsState.defineDepositAmounts}
                onChange={toggleDefineDepositAmounts}
              />
              <label> Define specific deposit amounts by payment method </label>
            </div>

            {configurePaymentMethodsState.defineDepositAmounts && (
              <div className="configurepaymentmethods_details_depositamounts_list">
                {configurePaymentMethodsState.selectedPaymentMethods.map(
                  (selectedMethod, idx) => (
                    <div
                      className="configurepaymentmethods_details_depositamounts_list_depositamount"
                      key={idx}
                    >
                      <div className="configurepaymentmethods_details_depositamounts_list_depositamount_header">
                        <div className="configurepaymentmethods_details_depositamounts_list_depositamount_header_label">
                          {selectedMethod.label}
                        </div>
                        <X className="configurepaymentmethods_details_depositamounts_list_depositamount_header_close" />
                      </div>
                      <input
                        className="configurepaymentmethods_details_depositamounts_list_depositamount_instruction"
                        type="text"
                        value={
                          configurePaymentMethodsState.depositAmounts[idx]
                            .instruction
                        }
                        onChange={(e) =>
                          handleDepositAmountChange(idx, e.target.value)
                        }
                      />
                    </div>
                  )
                )}
              </div>
            )}
          </div>

          <div
            className="configurepaymentmethods_details_addpaymentmethod"
            onClick={addPaymentMethod}
          >
            <Plus />
            <span>Add new payment method</span>
          </div>
        </div>

        {paymentMethodsSource == PAYMENT_METHODS_SOURCES.OTCTRADE && (
          <div className="configurepaymentmethods_continue">
            <div className="continue_control" onClick={goToConfirmOtcTrade}>
              continue
            </div>
          </div>
        )}

        {paymentMethodsSource == PAYMENT_METHODS_SOURCES.MARKETPLACE && (
          <div className="configurepaymentmethods_continue">
            <div className="continue_control" onClick={goToConfirmRequest}>
              continue
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default ConfigurePaymentMethods;
