import React, { useState } from 'react';
import "./myCompany.scss";
import editIcon from '../../../assets/edit_icon.svg';
import avatar from '../../../assets/profile-pic.png';
const MyCompany = () => {
  const [editMode, setEditMode] = useState(false);

  const handleEditClick = () => {
    setEditMode(!editMode);
  };
  return (
    <div className="my_company_page">
        {editMode ? (
            <div className='edit_company_banner'>
                <div className="button_box">
                    <div className="cancel_btn">Cancel</div>
                    <div className="save_btn">Save</div>
                </div>
                <div className="top_row">
                    <div className="company_profile">
                        <div className="company_avatar">C</div>
                        <span className="company_avatar_edit">Change photo</span>
                    </div>
                    <label className="company_details_input">
                        
                        <input 
                            placeholder="Company name Ltd."
                            name="companyName"
                        />
                    </label>
                    <label className="company_details_input">
                        <span className="company_details_input_label">
                            Country
                        </span>
                        <input 
                            placeholder="Enter country"
                            name="country"
                        />
                    </label>
                </div>
                <div className="bottom_row">
                    <label className="company_details_input">
                        <span className="company_details_input_label">
                            City
                        </span>
                        <input 
                            placeholder="Enter city"
                            name="city"
                        />
                    </label>
                    <label className="company_details_input">
                        <span className="company_details_input_label">
                            Street adress, building / apt.
                        </span>
                        <input 
                            placeholder="Enter address"
                            name="address"
                        />
                    </label>
                    <label className="company_details_input">
                        <span className="company_details_input_label">
                            ZIP/Postal code
                        </span>
                        <input 
                            placeholder="Enter postal code"
                            name="postalCode"
                        />
                    </label>
                </div>
                <div className="disclaimer">
                    The change of company name or location will require the company account to go through verification process once again.
                </div>
            </div>
        ):(
            <div className="my_company_page_banner">
                <div className="company_profile">
                    <div className="company_avatar">C</div>
                    <span className="company_avatar_edit">Change photo</span>
                </div>
                <div className="company_details">
                    <div className="company_name">Company name Ltd.</div>
                    <div className="company_address">Chesterfield avenue, 193G/5, SE17 9SE, London, United Kingdom</div>
                </div>
                <div className="company_details_edit"
                    onClick={handleEditClick}>
                    <img src={editIcon} alt="" />
                </div>
            </div>
        )}
        <div className="company_team">
            <div className="team_header">
                <div className="team_box">Team</div>
                <div className="plus_icon_box">+</div>
            </div>
            <div className="team_table">
                <div className="table_header">
                    <div className="spacer"></div>
                    <div className="username">User name</div>
                    <div className="email">Email</div>
                    <div className="role">Role</div>
                    <div className="status">Status</div>
                    <div className="action">Action</div>
                </div>
                <div className="table_row">
                    <div className="member_avatar">
                        <img src={avatar} alt="" />
                    </div>
                    <div className="member_username">Sarah Melbourne</div>
                    <div className="member_email">sarah.melbourne@gmail.com</div>
                    <div className="member_role">Admin/Checker</div>
                    <div className="member_status">Active</div>
                    <div className="member_action">Edit</div>
                </div>
                <div className="table_row">
                    <div className="member_avatar">
                        <img src={avatar} alt="" />
                    </div>
                    <div className="member_username">Meleh Saint</div>
                    <div className="member_email">meleh.saint@gmail.com</div>
                    <div className="member_role">Maker</div>
                    <div className="member_status">Inactive</div>
                    <div className="member_action">Edit</div>
                </div>
            </div>
        </div>
        <div className="my_company_page_footer">Close company account</div>
    </div>
  );
};
export default MyCompany;