import React, { useState, useEffect } from "react";
import { useRates } from "../../providers/Rates/RatesProvider";
import { formatAmount } from "../../common/formatAmount";

const SingleWalletBalance = ({wallet}) => {	
	const { rates, getRate } = useRates();
	const [accountTotalBalance, setAccountTotalBalance] = useState(0); 
	useEffect(() => {	
		let TotalAmount = 0;
		try{
            let rate = getRate({
                rates,
                from: wallet.currency,
                to: 'USD',
            });
            rate = parseFloat(rate).toFixed(4);
            TotalAmount = parseFloat(TotalAmount) +( parseFloat(wallet.balance) * parseFloat(rate));
        } catch(e){   
            console.log(e.message);
        }
	    setAccountTotalBalance(`USD ${formatAmount(parseFloat(TotalAmount).toFixed(4))}`);
    }, [wallet]);

	return (
	  <>   
		<span className="text-left balance_amount_">{accountTotalBalance}</span>
	  </> 
	);
};
export default SingleWalletBalance;
